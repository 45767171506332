<template>
  <div class="products">
    <ProductListGrid
      v-bind:parentCategoryId="category.categoryId"
      :key="category.categoryId"
      @productsCount="updateCount"
    />
  </div>
</template>
<style scoped lang="scss"></style>
<script>
import ProductListGrid from "@/components/product/ProductListGrid";
import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "CategoryMed",
  mixins: [categoryMixins],
  components: {
    ProductListGrid
  },
  data() {
    return { count: 0 };
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    return {
      title: get(
        this.category,
        "metaData.category_seo.SEO_TITLE",
        this.category.name
      ),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: get(
            this.category,
            "metaData.category_seo.SEO_DESCRIPTION",
            this.category.name
          )
        }
      ]
    };
  }
};
</script>
