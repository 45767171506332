import { render, staticRenderFns } from "./CategoryCustom.vue?vue&type=template&id=4e62efe1&scoped=true&"
import script from "./CategoryCustom.vue?vue&type=script&lang=js&"
export * from "./CategoryCustom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e62efe1",
  null
  
)

export default component.exports